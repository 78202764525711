<template>
  <div class="pageContol enterpriseInternalTrainingOrder">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">企业内训</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">企业内训列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="订购企业" class="searchboxItem ci-full">
              <span class="itemLabel">订购企业:</span>
              <el-select
                size="small"
                v-model="searchData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="企业负责人" class="searchboxItem ci-full">
              <span class="itemLabel">企业负责人:</span>
              <el-input
                v-model="searchData.enterpriseChargePerson"
                size="small"
                placeholder="请输入负责人名称"
                clearable
              />
            </div>
            <div title="联系方式" class="searchboxItem ci-full">
              <span class="itemLabel">联系方式:</span>
              <el-input
                v-model="searchData.mobile"
                size="small"
                placeholder="请输入联系方式"
                clearable
              />
            </div>
            <div title="订单状态" class="searchboxItem ci-full">
              <span class="itemLabel">订单状态:</span>
              <el-select
                size="small"
                v-model="searchData.orderState"
                clearable
                placeholder="请选择订单状态"
              >
                <el-option
                  v-for="item in orderStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div class="df" style="padding-right: 40px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="handleAddEditOrder('add', orderId = '')"
                >新增订单</el-button
              >
            </div>
          </div>
        </div>
        <div class="amountSummary">
          签约金额汇总：<span>￥{{ sumAmountMoney }}</span>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :cell-style="cellStyle"
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="序号"
                align="center"
                fixed
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="订单编号"
                align="center"
                width="185"
                prop="orderNo"
              />
              <el-table-column
                label="企业名称"
                align="center"
                prop="compName"
                min-width="180"
              />
              <el-table-column
                label="企业负责人"
                align="center"
                prop="chargePerson"
                min-width="160"
                show-overflow-tooltip
              />
              <el-table-column
                label="联系方式"
                align="center"
                min-width="120"
                prop="mobile"
              />
              <el-table-column
                label="签约金额"
                align="center"
                min-width="120"
                prop="amountMoney"
                class="resd"
              >
                <template slot-scope="scope">
                  ￥{{ scope.row.amountMoney }}
                </template>
              </el-table-column>
              <el-table-column
                label="内容有效期至"
                align="center"
                width="120"
                prop="endDate"
              >
                <template slot-scope="scope">
                  {{ scope.row.endDate | momentDate }}
                </template>
              </el-table-column>

              <el-table-column
                label="关联课程"
                prop="compCourseNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="关联试卷"
                prop="compPaperNum"
                show-overflow-tooltip
              />
              <el-table-column
                label="订单状态"
                align="center"
                prop="orderState"
              >
                <template slot-scope="scope">
                  {{ $setDictionary("ET_ORDER_STATE", scope.row.orderState) }}
                </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="center"
                prop="createTime"
                width="160"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="left"
                width="200"
                fixed="right"
              >
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleLook(scope.row.orderId)"
                    >查看</el-button
                  >
                  <el-button
                    v-if="scope.row.orderState == '10'"
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleAddEditOrder('edit', scope.row.orderId)"
                    >编辑</el-button
                  >
                  <el-button
                    v-if="scope.row.orderState == '10'"
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleContentManagement(scope.row.orderId,scope.row.orderNo,scope.row.compName)"
                    >内容管理</el-button
                  >
                  <el-button
                    v-if="scope.row.orderState == '10'"
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="handleDistribute(scope.row.orderId)"
                    >分发</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 新增&&编辑企业内训订单 弹窗 -->
    <el-dialog
      :visible.sync="dialogVisibleOrder"
      top="5%"
      width="50%"
      :title="dialogVisibleOrderTitle"
      :center="true"
      @close="formCancel('ruleForm')"
    >
      <div class="addtestpop">
        <div class="ovy-a">
          <el-form
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            label-width="8rem"
            class="commodity-form"
          >
            <el-form-item label="企业名称" prop="compId" class="form-item">
              <el-select
                size="small"
                v-model="ruleForm.compId"
                remote
                :remote-method="getAddCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in companyAddList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="企业负责人"
              prop="chargePerson"
              class="form-item"
            >
              <el-input
                v-model="ruleForm.chargePerson"
                placeholder="请输入企业负责人"
                size="small"
                maxlength="50"
                show-word-limit
              />
            </el-form-item>
            <el-form-item label="联系方式" prop="mobile" class="form-item">
              <el-input
                v-model="ruleForm.mobile"
                placeholder="请输入联系方式"
                size="small"
              />
            </el-form-item>
            <el-form-item label="签约金额" prop="amountMoney" class="form-item">
              <el-input
                v-model="ruleForm.amountMoney"
                @keyup.native="
                  ruleForm.amountMoney = zF.oninput2(ruleForm.amountMoney, 2)
                "
                placeholder="请输入签约金额"
                size="small"
              >
               <template slot="prepend">￥</template>
               </el-input>
            </el-form-item>
            <el-form-item label="内容有效期至" prop="endDate" class="form-item">
              <el-date-picker
                v-model="ruleForm.endDate"
                size="small"
                type="date"
                placeholder="选择内容有效期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
          <div class="btnBox">
            <el-button size="small" @click="formCancel('ruleForm')"
              >取消</el-button
            >
            <el-button type="primary" size="small" @click="formSave('ruleForm')"
              >保存</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue"; //引入表格无数据提示
import PageNum from "@/components/PageNum.vue"; //引入分页-组件
import List from "@/mixins/List"; //引入列表 - 混入
import { resetKeepAlive } from "@/utils/common"; //缓存页面
import { mapGetters } from "vuex";
export default {
  name: "enterpriseInternalTrainingOrder",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //检索条件
      companyList: [], //企业数据
      orderStateList: [], //订单状态
      searchData: {
        compId: "", //企业Id
        enterpriseChargePerson: "", //企业负责人
        mobile: "", // 联系方式
        orderState: "", //订单状态
      },
      sumAmountMoney: 0, // 签约金额汇总
      dialogVisibleOrder: false, //新增编辑订单弹窗是否显示
      dialogVisibleOrderTitle: "", //新增编辑订单表头
      //订单表单数据
      companyAddList: [], //新增 --企业数据
      ruleForm: {
        compId: "", //企业Id
        chargePerson: "", //负责人
        mobile: "", //联系方式
        amountMoney: "", //签约金额
        endDate: "", //有效期
        orderId:'',
      },
      // 新增&&编辑订单 - 数据校验
      rules: {
        compId: [{ required: true, message: "请选择企业", trigger: "change" }],
        chargePerson: [
          { required: true, message: "请输入负责人名称", trigger: "blur" },
        ],
        mobile: [
          { required: true, validator: this.$validatePhone, trigger: "blur" },
        ],
        amountMoney: [
          { required: true, message: "请输入签约金额", trigger: "blur" },
        ],
        endDate: [
          { required: true, message: "请选择内容有效期", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.getOrderState();
    this.cellStyle();
  },
  mounted() {},
  computed: {},
  methods: {
    //动态调整单元格样
    cellStyle({ row, column}) {
      if (column.label == "签约金额") return "color:red";
      if (column.label == "订单状态") {
        if(row.orderState == "20") {
          return "color:#70B603";
        }
      } 
    },
    // 获取 - 所属机构
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    // 新增获取 - 所属机构
    getAddCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyAddList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyAddList = [];
      }
    },
    //获取码值--订单状态
    getOrderState() {
      const orderStateList = this.$setDictionary("ET_ORDER_STATE", "list");
      for (const key in orderStateList) {
        this.orderStateList.push({
          value: key,
          label: orderStateList[key],
        });
      }
    },
    //获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum,
        pageSize: this.pageSize,
      };
      if (this.searchData.compId) {
        params.compId = this.searchData.compId;
      }
      if (this.searchData.orderState) {
        params.orderState = this.searchData.orderState;
      }
      if (this.searchData.mobile) {
        params.mobile = this.searchData.mobile;
      }
      if (this.searchData.enterpriseChargePerson) {
        params.chargePerson = this.searchData.enterpriseChargePerson;
      }
      this.doFetch({
        url: "/biz/et/order/pageListOrder",
        params,
        pageNum,
      });
      this.getSumAmountMoney(params);
    },
    //获取签约金额汇总
    getSumAmountMoney(params) {
      this.$post("/biz/et/order/sumAmountMoney", params).then((res) => {
        this.sumAmountMoney = res.data || 0;
      });
    },
    //新增&&编辑 --订单弹窗
    handleAddEditOrder(stu, orderId = "") {
      this.dialogVisibleOrder = true;
      if (stu == "add") {
        this.dialogVisibleOrderTitle = "新增订单";
        this.ruleForm.orderId = ''
      } else {
        this.dialogVisibleOrderTitle = "修改订单";
        this.getOrderInfo(orderId);
      }
    },
    // 编辑订单回显数据
    getOrderInfo(orderId) {
      this.$post("/biz/et/order/getCompOrder", { orderId }).then((res) => {
        if (res.status == "0") {
          this.getCompany(res.data.compId);
          this.ruleForm = {
            ...res.data,
            endDate: res.data.endDate.replaceAll("/", "-"),
          };
        }
      });
    },
    // 根据compId查询单位名称
    getCompany(compId) {
      this.$post("/sys/company/id", { compId }).then((res) => {
        this.companyAddList = [res.data];
      });
    },
    // 新增&&编辑订单 -- 保存
    formSave(formName = "ruleForm") {
      const el = this.$refs[formName];
      el.validate((valid) => {
        if (valid) {
          this.doAjax(formName);
        } else {
          this.$message({
            type: "warning",
            message: "请输入必填项",
          });
        }
      });
    },
    doAjax(formName) {
      const that = this;
      const ruleForm = that.ruleForm || {};
      let parameter = {
        ...ruleForm,
      };
      this.$post("/biz/et/order/saveOrUpdate", parameter).then((res) => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: "保存成功",
          });
          this.dialogVisibleOrder = false;
          this.$refs[formName].resetFields();
          this.getData(-1);
        }
      });
    },
    // 新增题库 -- 取消
    formCancel(formName) {
      this.dialogVisibleOrder = false;
      this.$refs[formName].resetFields();
      // this.$router.back();
    },
    //查看
    handleLook(orderId) {
      this.$router.push({
        path: "/web/CustomPrograms/enterpriseInternalTrainingOrderView",
        query: {
          orderId,
          stu: "look",
        },
      });
    },
    //内容管理
    handleContentManagement(orderId,orderNo,compName) {
      this.$router.push({
        path: "/web/CustomPrograms/distributeContent",
        query: {
          orderId,
          orderNo,
          compName,
        },
      });
    },
    //分发
    handleDistribute(orderId) {
      this.$confirm(
        "订单分发后，不支持编辑和内容管理操作且不可撤回，确定信息无误继续分发?",
        "分发订单",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          closeOnClickModal: false,
        }
      )
        .then(() => {
          this.$post("/biz/et/order/distributeOrder", { orderId }).then(
            (res) => {
              this.$message.success(res.message);
              this.getData(-1);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消分发",
          });
        });
    },
    //获取高度
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
    },
    beforeRouteLeave: resetKeepAlive,
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh || val.query.refresh == "true") {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.enterpriseInternalTrainingOrder {
  .itemLabel {
    min-width: 6rem;
  }
}
.amountSummary {
  padding-left: 12px;
  span {
    color: red;
  }
}
.btnBox {
  display: flex;
  justify-content: center;
}
/deep/.el-tooltip__popper {
  max-width: 45% !important;
}
</style>
